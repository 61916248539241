import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormBuilder() {
  // Use toast
  const toast = useToast()

  const formBuilderFieldTypes = {
    PLAIN_TEXT: 'plain_text',
    SHORT_TEXT: 'short_text',
    LONG_TEXT: 'long_text',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE: 'phone',
    DATE: 'date',
    DOB: 'dob',
    DROPDOWN: 'dropdown',
    MULTI_SELECT: 'multi_select',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio_button',
    FILE_UPLOAD: 'file_upload',
    SIGNATURE: 'signature',
    PASSWORD: 'password',
  }

  const postForm = queryParams => axios
    .post('/auth/forms', queryParams, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New programs form has posted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting programs form',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updForm = queryParams => new Promise((resolve, reject) => {
    axios
      .put(`/auth/forms/${queryParams.id}`, queryParams, {
        headers: {
          WithoutSchoolFilter: true,
        }
      })
      .then(response => {
        resolve('success')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Form was successfully updated',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })
        return response.data.data
      })
      .catch(error => {
        const { response } = error
        let message = ''
        if (response.status === 422) {
          message = (Object.values(response.data.errors)).flat().toString()
        }
        reject(new Error(message))
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating form',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: message,
          },
        })
        return response
      })
  })

  const fetchRegistrationAttributeHandlers = () => axios
    .get('/auth/registration-attribute-handlers', {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching data',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`, {
      headers: {
        WithoutSchoolFilter: true,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    formBuilderFieldTypes,
    postForm,
    updForm,
    fetchForm,
    fetchRegistrationAttributeHandlers,
  }
}
