<template>
  <div>

    <b-card
      no-body
    >

      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-between"
      >

        <h2 class="font-weight-normal">
          Form Builder
        </h2>

        <div
          class="d-flex align-items-center justify-content-between"
        >
          <b-col
            md="2"
            xl="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            right
          >
            <label
              class="mr-1 mb-0 col-form-label"
            >Active
            </label>
            <b-form-checkbox
              v-model="activeStatus"
              switch
              class="d-inline-block ml-50 mr-1"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>

          <div class="action-button">
            <b-dropdown
              class="fixed"
              toggle-class="p-0"
              variant="primary"
              dropleft
              no-caret
              right
            >
              <template
                #button-content
              >
                <feather-icon
                  icon="GridIcon"
                  size="19"
                  class="m-50"
                />
              </template>

              <b-dropdown-item
                @click="addNewFieldsRow"
              >
                Add Field
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal="'add-condition-'+ type"
              >
                Add Condition
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div
        v-if="isLoading"
        style="padding: 20px"
      >
        Loading...
      </div>

      <template
        v-else
      >
        <b-col
          v-if="requiredFields"
          cols="12"
          class="border-bottom"
        >
          <b-alert
            show
            class="p-1"
          >
            <p>For using this form as a registration you need to use all the required field keys for user creation:</p>
            <p><b>{{ requiredFields }}</b></p>
          </b-alert>
        </b-col>
        <validation-observer
          :ref="`form-builder`"
          tag="form"
        >
          <b-row class="mt-2 ml-2 mb-2">
            <b-col md="4">
              <b-form-group
                label="Form Name"
                label-for="form-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Form Name"
                  rules="required"
                >
                  <b-form-input
                    id="form-name"
                    v-model="formName"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 ml-2 mb-2">
            <b-col md="4">
              <b-form-group
                label="Number of registered Users"
                label-for="number-of-registered-users"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number of registered Users"
                  rules="required|min_value:1|max_value:2"
                >
                  <b-form-input
                    id="number-of-registered-users"
                    v-model="numberOfUsers"
                    type="number"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              v-if="numberOfUsers > 1"
              right
            >
              <label
                class="mr-1 mb-0 col-form-label"
              >Send family invitation for second user
              </label>
              <b-form-checkbox
                v-model="sendInvitation"
                switch
                class="d-inline-block ml-50 mr-1"
              >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row
            v-if="errorMessage && showError"
            class="my-2 ml-2"
          >
            <b-col md="6">
              <small class="text-danger">
                {{ errorMessage }}
              </small>
            </b-col>
          </b-row>

          <!-- Field Row -->
          <draggable
            v-model="fieldsList"
            tag="ul"
            class="list-group list-group-flush cursor-move"
          >
            <FormBuilderRow
              v-for="(item, index) in fieldsList"
              ref="formBuilderRow"
              :key="item.frontID + item.id"
              :fields-list="fieldsList"
              :fields-data="item"
              :field-index="index"
              :following-fields="followingFields"
              @removeFieldsRow="removeFieldsRow"
              @removeCondition="removeCondition"
              @moveItem="moveItem"
              @closeOpenedContextMenu="closeOpenedContextMenu"
            />
          </draggable>
        </validation-observer>

        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="showValidationError && notSelectedRequiredFieldsKeys.length"
            class="p-1"
          >
            <p>You should add all required field keys at least for one user or make the form inactive.</p>
            <p>Here is a list of unspecified fields keys: <b>{{ notSelectedRequiredFieldsKeys }}</b></p>
          </b-alert>
        </b-col>

        <!-- Buttons Wrap-->
        <div
          class="card-footer"
        >
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="!canUpdateForm"
            @click="saveForm"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-primary"
            :to="type === 'form' ? { name: 'admin-form-folders'} : { name: 'admin-program'}"
          >
            Cancel
          </b-button>
        </div>
      </template>

    </b-card>

    <confirmation-modal
      :type="'-form-update'"
      :title="'The form is used for the registration form'"
      :message="'Are you sure you want to inactivate it? It will be detached from the registration form'"
      :close-only="true"
      @close-modal="$bvModal.hide('confirmation-form-update')"
      @confirm="saveData"
    />

    <AddConditionModal
      :type="type"
      :condition-data="conditions"
      :fields-data="fieldsForConditions"
      @saveConditions="setFormConditions"
    />

    <MoveBeforeFieldModal
      :type="type"
      :fields-list="fieldsList"
      :field-to-move="fieldToMove"
      @moveItemBefore="moveItemBefore"
    />

  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BAlert,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min_value, max_value } from '@validations'

import { formConditionsMethods } from '@core/mixins/formConditionsMethods'
import { v4 as uuidv4 } from 'uuid'

import useFormBuilder from '@/views/components/form-builder/useFormBuilder'
import FormBuilderRow from '@/views/components/form-builder/FormBuilderRow.vue'
import MoveBeforeFieldModal from '@/views/components/form-builder/modals/MoveBeforeFieldModal.vue'
import AddConditionModal from '@/views/components/form-builder/modals/AddConditionModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  name: 'FormBuilder',
  components: {
    ConfirmationModal,
    ValidationObserver,
    ValidationProvider,

    BCard,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BAlert,

    draggable,

    FormBuilderRow,
    AddConditionModal,
    MoveBeforeFieldModal,
  },
  mixins: [formConditionsMethods],
  props: {
    type: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      formBuilderFieldTypes,
      postForm,
      updForm,
      fetchForm,
      fetchRegistrationAttributeHandlers
    } = useFormBuilder()

    return {
      formBuilderFieldTypes,
      postForm,
      updForm,
      fetchForm,
      fetchRegistrationAttributeHandlers,
    }
  },
  data() {
    return {
      selected: null,
      fieldsList: [],
      conditions: [],
      fieldsForConditions: [],
      fieldsCount: 0,
      activeStatus: false,
      alreadyInUse: false,
      isLoading: false,
      requiredFields: [],
      attributeHandlers: [],
      followingFields: [],
      formName: null,
      numberOfUsers: 1,
      sendInvitation: false,
      formType: null,
      errorMessage: '',
      fieldToMove: {
        label: '',
      },
      showValidationError: false,
      required,
      min_value,
      max_value,
    }
  },
  computed: {
    showError() {
      return this.fieldsList.length === 0
    },
    canUpdateForm() {
      return true
    },
    notSelectedRequiredFieldsKeys() {
      const selectedValues = this.fieldsList.map(field => field.attribute_field_name ? field.attribute_field_name.split('#')[1] : '').flat()

      return this.requiredFields
        .filter(field => !selectedValues.includes(field))
        .join(', ')
    },
  },
  watch: {
    fieldsList: {
      deep: true,
      handler(val) {
        this.fieldsCount = this.fieldsList.length
        this.fieldsForConditions = this.setFieldsForConditions(val)
      },
    },
    numberOfUsers() {
      this.setFollowingFields()
    },
  },
  async created() {
    if (this.formId) await this.setForm(this.formId)

    this.attributeHandlers = await this.fetchRegistrationAttributeHandlers()
    this.requiredFields = this.attributeHandlers.filter(attribute => attribute.is_required)
      .reduce((arr, item) => {
        arr.push(item.field_name)
        return arr
      }, [])

    this.setFollowingFields()
    this.fieldsForConditions = this.setFieldsForConditions(this.fieldsList)

    this.fieldsList.sort((a, b) => a.order - b.order)
  },
  methods: {
    setFollowingFields() {
      this.followingFields = []
      for (let i = 1; i <= this.numberOfUsers; i++) {
        this.followingFields = this.followingFields.concat(this.attributeHandlers.reduce((arr, item) => {

          arr.push({value: i + '#' + item.field_name, text: 'user #' + i + ': ' + item.field_name})
          return arr
        }, [{value: i + '#', text: 'user #' + i + ': PPA'}]))
      }
    },
    setForm(val) {
      this.fetchForm(val)
        .then(response => {
          if (response === 404) {
            this.isLoading = true
            return
          }

          this.activeStatus = response.is_active
          this.alreadyInUse = response.is_use_school_registration_forms
          this.formName = response.name
          this.formType = response.type
          this.numberOfUsers = response.additional_settings.numberOfUsers ?? 1
          this.sendInvitation = response.additional_settings.sendInvitation ?? false
          this.fieldsList = response.fields.map(item => {
            return {
              isCondition: false,
              isVisibility: false,
              ...item,
            }
          })

          this.fieldsCount = this.fieldsList.length

          this.isLoading = false

          this.setFormConditions(response.conditions)
        })
    },
    isFieldKeyExists(key) {
      return this.fieldsList.some(field => field.name === `field_${key}`)
    },
    addNewFieldsRow() {
      do {
        this.fieldsCount += 1
      } while (this.isFieldKeyExists(this.fieldsCount))

      this.fieldsList.push({
        id: uuidv4(),
        type: null,
        label: `Field ${this.fieldsCount}`,
        name: `field_${this.fieldsCount}`,
        required: false,
        use_prefill: false,
        options: [],
        default: '',
        order: this.fieldsCount,
        isCondition: false,
        isVisibility: false,
        attribute_field_name: null,
        section_type: 'registration',
      })
    },
    removeFieldsRow(ID) {
      const curItem = this.fieldsList.findIndex(item => item.id === ID)
      this.fieldsList.splice(curItem, 1)
    },
    async saveForm() {
      if (this.fieldsList.length < 1) {
        this.errorMessage = 'Please, add some field before safe form'
        return
      }

      if (this.notSelectedRequiredFieldsKeys.length && this.activeStatus) {
        this.showValidationError = true
        return
      }

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs['form-builder'].validate().then(success => {
          if (success) {
            if (this.alreadyInUse && !this.activeStatus) {
              this.$bvModal.show('confirmation-form-update')
            } else {
              this.saveData()
            }
          }
        })
      })
    },
    async saveData() {
      this.errorMessage = ''
      this.$bvModal.hide('confirmation-form-update')
      this.isLoading = true

      const fields = this.fieldsList.filter(item => item.type !== 'checkbox' || (item.type === 'checkbox' && item.options[0]))

      const updatedData = {
        fields,
        conditions: this.conditions,
        type: this.formType || this.type,
        name: this.formName,
        is_active: this.activeStatus,
        additional_settings: {
          numberOfUsers: this.numberOfUsers,
          sendInvitation: this.sendInvitation,
        },
      }

      if (this.formId) {
        updatedData.id = this.formId
        await this.updForm(updatedData).then(() => {
          this.setForm(this.formId)
        }).finally(() => {
          this.isLoading = false
        })
        return
      }

      await this.postForm(updatedData).then(response => {
        this.isLoading = false
        this.$emit('createdForm', response.id)
      })
    },
    moveItem(param) {
      this.fieldToMove = param.item
      if (param.action === 'moveToEnd') {
        this.fieldsList.push(this.fieldsList.splice(this.fieldsList.indexOf(this.fieldToMove), 1)[0])
      } else if (param.action === 'moveToBeginning') {
        this.fieldsList.unshift(this.fieldsList.splice(this.fieldsList.indexOf(this.fieldToMove), 1)[0])
      } else {
        this.$bvModal.show(`move-before-field-${this.type}`)
      }
    },
    moveItemBefore(field) {
      const oldIndex = this.fieldsList.indexOf(this.fieldToMove)
      const newIndex = this.fieldsList.findIndex(item => item.id === field.id)
      this.fieldsList.splice(oldIndex, 1)
      if (oldIndex < newIndex) {
        this.fieldsList.splice(newIndex - 1, 0, this.fieldToMove)
      } else {
        this.fieldsList.splice(newIndex, 0, this.fieldToMove)
      }
    },
    closeOpenedContextMenu() {
      this.$refs.formBuilderRow.forEach(row => {
        row.$refs.menu.close()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .header,
  .card-footer {
    border-top-right-radius: calc(0.428rem - 1px);
    border-top-left-radius: calc(0.428rem - 1px);
    padding: 1.45rem 2.25rem;
    background-color: #fff;
  }
  .header {
      border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }
  .card-footer {
      border-top: 1px solid rgba(34, 41, 47, 0.125) !important;
  }
  .type-pill {
    padding: 10px;
    background-color: #828589;
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }

  .action-button{
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 100;
  }
  .fixed{
    position: fixed;
    transform: translateX(-100%);
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
