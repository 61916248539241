<template>

  <b-modal
    :id="'add-condition-'+ type"
    ref="addCondition"
    centered
    title="Add Condition"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    size="lg"
    class="p-0"
    :busy="isLoading"
    @show="setModalData"
    @ok="addCondition"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="formConditionsRules"
      >
        <AddConditionBlock
          v-for="(item, index) in conditionsArr"
          :key="item.id"
          :fields-data="fieldsData"
          :steps-options="stepsData"
          :conditions-data="item"
          :condition-index="index"
          @removeConditionsRow="removeConditionsRow"
        />
      </validation-observer>
      <!-- Add Button -->
      <b-button
        variant="outline-primary"
        @click="addNewConditionRow"
      >
        Add Condition
      </b-button>
    </b-overlay>
  </b-modal>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
import {
  BButton,
  BOverlay,
} from 'bootstrap-vue'

import AddConditionBlock from '@/views/components/form-builder/modals/components/AddConditionBlock.vue'

export default {
  components: {
    ValidationObserver,
    BButton,
    BOverlay,

    AddConditionBlock,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    fieldsData: {
      type: Array,
      default: () => {},
    },
    conditionData: {
      type: Array,
      default: () => {},
    },
    stepsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      conditionsArr: [],
      isLoading: false,
    }
  },
  methods: {
    setModalData() {
      this.conditionsArr = JSON.parse(JSON.stringify(this.conditionData))
    },
    addCondition(e) {
      e.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.formConditionsRules.validate().then(success => {
          if (success) {
            this.isLoading = true
            this.$emit('saveConditions', this.conditionsArr)
            this.$nextTick(() => {
              this.isLoading = false
              this.$bvModal.hide(`add-condition-${this.type}`)
            })
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    addNewConditionRow() {
      this.conditionsArr.push({
        id: uuidv4(),
        scoped_conditions: [
          [
            {
              field_id: null,
              operator: '',
              value: '',
            },
          ],
        ],
        related_id: '',
        related_type: 'Field',
        action: '',
      })
    },
    removeConditionsRow(ID) {
      const curItem = this.conditionsArr.findIndex(item => item.id === ID)
      this.conditionsArr.splice(curItem, 1)
    },
    resetModal() {
      this.conditionsArr = JSON.parse(JSON.stringify(this.conditionData))
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
