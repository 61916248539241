var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Field Key"}},[_c('validation-provider',{attrs:{"name":"Field Key","rules":"required","vid":("Field Key" + _vm.conditionIndex + _vm.conditionBlockIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.fieldsData,"label":"text","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select a Field"},on:{"input":_vm.setOptions},model:{value:(_vm.condition.field_id),callback:function ($$v) {_vm.$set(_vm.condition, "field_id", $$v)},expression:"condition.field_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Operator"}},[_c('validation-provider',{attrs:{"name":"Operator","rules":"required","vid":("Operator" + _vm.conditionIndex + _vm.conditionBlockIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.operatorsOptions,"disabled":!_vm.condition.field_id,"label":"text","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select an Operator"},on:{"input":_vm.setOperator},model:{value:(_vm.condition.operator),callback:function ($$v) {_vm.$set(_vm.condition, "operator", $$v)},expression:"condition.operator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.isDateOperator)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Value"}},[_c('validation-provider',{attrs:{"name":"Value","rules":"required","vid":("ValueDatePicker" + _vm.conditionIndex + _vm.conditionBlockIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.condition.value),callback:function ($$v) {_vm.$set(_vm.condition, "value", $$v)},expression:"condition.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,565649101)})],1)],1):_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Value"}},[[_c('validation-provider',{attrs:{"name":"Value","rules":{ required_if: { target: ("Operator" + _vm.conditionIndex + _vm.conditionBlockIndex), values: _vm.operatorsWithValue } },"vid":("Value" + _vm.conditionIndex + _vm.conditionBlockIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.valueOptions.length >= 1 && _vm.condition.operator !== '==contains' && !_vm.isOperatorWithoutValue)?_c('v-select',{attrs:{"placeholder":"Select Value","clearable":false,"options":_vm.valueOptions},model:{value:(_vm.condition.value),callback:function ($$v) {_vm.$set(_vm.condition, "value", $$v)},expression:"condition.value"}}):(_vm.condition.operator == '==contains')?_c('v-select',{ref:_vm.conditionIndex,class:{'is-invalid': errors.length > 0 },attrs:{"placeholder":"Select Value","clearable":false,"taggable":_vm.valueOptions.length == 0,"multiple":_vm.condition.operator === '==contains',"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.valueOptions},scopedSlots:_vm._u([(_vm.valueOptions.length == 0)?{key:"no-options",fn:function(){return [_vm._v(" Type and press Enter to add value. ")]},proxy:true}:null],null,true),model:{value:(_vm.condition.value),callback:function ($$v) {_vm.$set(_vm.condition, "value", $$v)},expression:"condition.value"}}):_c('b-form-input',{attrs:{"disabled":!_vm.condition.field_id || _vm.isOperatorWithoutValue},model:{value:(_vm.condition.value),callback:function ($$v) {_vm.$set(_vm.condition, "value", $$v)},expression:"condition.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})]],2)],1),_c('b-col',{attrs:{"cols":"1","align-self":"center"}},[(!_vm.hideDeleteRowIcon)?_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"Trash2Icon","size":"21"},on:{"click":_vm.removeConditionRow}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }